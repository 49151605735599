import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllCountriesService: () => Promise<{
	data: any;
	status: number;
}> = async () => {
	return fetchService({
		url: `${Apis.COUNTRIES}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
	});
};

export { GetAllCountriesService };
