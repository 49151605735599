import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllDocumentsService: (
	token: string,
	folderId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, folderId: string) => {
	return fetchService({
		url: `${Apis.DOCUMENTS}?folderId=${folderId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetDocumentService: (
	token: string,
	documentId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, documentId: string) => {
	return fetchService({
		url: `${Apis.DOCUMENT}?documentId=${documentId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const GetDocumentsOfLocationService: (
	locationId: number,
) => Promise<{
	data: any;
	status: number;
}> = async (locationId: number) => {
	return fetchService({
		url: Apis.DOCUMENTS + '?locationId=' + locationId,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
	});
};
const GetNumberOfDocumementsService: (
	locationId: string,
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (locationId: string, token: string) => {
	return fetchService({
		url: `${Apis.NUMBER_OF_DOCUMENTS}/${locationId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddDocumentService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_DOCUMENT,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const UpdateDocumentService: (
	token: string,
	documentId: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (
	token,
	documentId,
	body,
) => {
	return fetchService({
		url: `${Apis.UPDATE_DOCUMENT}/${documentId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const UploadDocumentService: (
	token: string,
	data: FormData,
) => Promise<{ data: any; status: number }> = async (token, data) => {
	return fetchService({
		url: Apis.UPLOAD_DOCUMENT,
		method: 'POST',
		addContentTypeApplicationJson: false,
		token: token,
		formData: data,
	});
};

const DeleteDocumentService: (
	token: string,
	documentId: string,
) => Promise<{ data: any; status: number }> = async (token, documentId) => {
	return fetchService({
		url: `${Apis.DELETE_DOCUMENT}/${documentId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	GetAllDocumentsService,
	AddDocumentService,
	GetDocumentsOfLocationService,
	UploadDocumentService,
	GetNumberOfDocumementsService,
	DeleteDocumentService,
	GetDocumentService,
	UpdateDocumentService,
};
