import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetTransfersService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.TRANSFER_OWNERSHIPS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddTransferLocationOwnershipService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_TRANSFER_OWNERSHIP,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const CancelTransferLocationOwnershipService: (
	transferId,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	transferId,
	body,
	token,
) => {
	return fetchService({
		url: `${Apis.CANCEL_TRANFER_LOCATION_OWNERSHIP}?transferId=${transferId}`,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	GetTransfersService,
	AddTransferLocationOwnershipService,
	CancelTransferLocationOwnershipService,
};
