import React, {
	lazy,
	ReactElement,
	Suspense,
	useEffect,
	useState,
} from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useHistory,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import './App.scss';
import RouteLoader from './components/routeLoader/RouteLoader';
import Header from './common/Header/Header';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
	resetLogedInUserState,
	selectLogedInUserInfo,
	setProfile,
} from './features/logedInUser/logedInUserSlice';
import Footer from './common/Footer/Footer';
import { GetMyProfile, LogoutService } from './services';
import { resetAllSearch } from './features/search/searchSlice';

const routes = [
	{
		path: '/',
		component: lazy(() => import('./routes/mainPage/mainPage')),
		exact: true,
	},
	// {
	// 	path: '/',
	// 	component: lazy(() => import('./routes/home/Home')),
	// 	exact: true,
	// },
	// {
	// 	path: '/services',
	// 	component: lazy(() => import('./routes/services/Services')),
	// },
	// {
	// 	path: '/location',
	// 	component: lazy(() => import('./routes/location/Location')),
	// },
	// {
	// 	path: '/search',
	// 	component: lazy(() => import('./routes/search/Search')),
	// },
	// {
	// 	path: '/search-again',
	// 	component: lazy(() => import('./routes/searchAgain/SearchAgain')),
	// },
	// {
	// 	path: '/advisor/:advisorId/contact',
	// 	component: lazy(() => import('./routes/contact/Contact')),
	// },
	// {
	// 	path: '/advisor/:advisorId/add-review',
	// 	component: lazy(() => import('./routes/addReview/AddReview')),
	// },
	// {
	// 	path: '/advisor/:advisorId',
	// 	component: lazy(() => import('./routes/advisor/AdvisorRoute')),
	// },
	{
		path: '/linkedin',
		component: lazy(() => import('./routes/linkedin/Linkedin')),
	},
	{
		path: '/signin/client',
		component: lazy(() => import('./routes/signin/SigninAsClient')),
		exact: true,
	},
	{
		path: '/signin/client/signin-with-email',
		component: lazy(
			() => import('./routes/signin/signinWithEmail/signinWithEmail'),
		),
	},
	{
		path: '/signin/client/signup-with-email',
		component: lazy(
			() => import('./routes/signin/signupWithEmail/signupWithEmail'),
		),
	},
	{
		path: '/signin/client/verify-code/:userId/:type?',
		component: lazy(() => import('./routes/signin/VerifyCode/verifyCode')),
	},
	{
		path: '/signin/client/forgert-password',
		component: lazy(
			() => import('./routes/signin/ForgetPassword/ForgetPassword'),
		),
	},
	{
		path: '/signin/client/change-password/:operationId',
		component: lazy(
			() => import('./routes/signin/ChangePassword/ChangePassword'),
		),
	},
	// {
	// 	path: '/client/reviews',
	// 	component: lazy(() => import('./routes/client/Reviews')),
	// },
	// {
	// 	path: '/client/account',
	// 	component: lazy(() => import('./routes/client/Account')),
	// },
	{
		path: '/business-suite/edit-document/:documentId',
		component: lazy(
			() =>
				import(
					'./routes/businessSuite/Document/AddDocument/EditDocumentNew'
				),
		),
		exact: true,
	},
	{
		path:
			'/business-suite/equipment-device-documents/:locationId/:folderId',
		component: lazy(
			() =>
				import(
					'./routes/businessSuite/EquipmentDeviceDocument/EquipmentDeviceDocument'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/add-equipment/:folderId',
		component: lazy(
			() =>
				import(
					'./routes/businessSuite/EquipmentDeviceDocument/AddEquipment/AddEquipmentNew'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/edit-equipment/:equipmentId',
		component: lazy(
			() =>
				import(
					'./routes/businessSuite/EquipmentDeviceDocument/AddEquipment/EditEquipmentNew'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/documents/:locationId/:folderId/:categoryType',
		component: lazy(
			() => import('./routes/businessSuite/Document/Document'),
		),
		exact: true,
	},
	{
		path: '/business-suite/notifications/:locationId/:folderId?/:type',
		component: lazy(
			() => import('./routes/businessSuite/Notifications/Notifications'),
		),
		exact: true,
	},
	{
		path: '/business-suite/add-document/:folderId/:categoryType?',
		component: lazy(
			() =>
				import(
					'./routes/businessSuite/Document/AddDocument/AddDocumentNew'
				),
		),
		exact: true,
	},
	{
		path: '/business-suite/:pathLocationId?/:type?/:scrollHeight?',
		component: lazy(() => import('./routes/businessSuite/Dashboard')),
		exact: true,
	},

	{
		path: '/personal',
		component: lazy(() => import('./routes/personal/Personal')),
	},
	{
		path: '/signin/advisor',
		component: lazy(() => import('./routes/signin/SigninAsAdvisor')),
	},
	{
		path: '/signin',
		component: lazy(() => import('./routes/signin/Signin')),
	},
	{
		path: '/cliam-user-dashboard',
		component: lazy(() => import('./routes/signin/ClaimYourUserDashboard')),
	},
	{
		path: '/verify-code',
		component: lazy(() => import('./routes/signin/VerifyCode')),
	},
	{
		path: '/claimed-suucessfully',
		component: lazy(() => import('./routes/signin/SuccessfullyClaimed')),
	},
	{
		path: '/admin-console',
		component: lazy(() => import('./routes/adminConsole/adminConsole')),
		exact: true,
	},
	{
		path: '/admin-console/manage-users',
		component: lazy(
			() => import('./routes/adminConsole/users/manageUsers/manageUsers'),
		),
		exact: true,
	},
	{
		path: '/admin-console/manage-users/view-access-history/:userId',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/users/viewAccessHistory/viewAccessHistory'
				),
		),
	},
	{
		path: '/admin-console/manage-users/user-information/:userId?',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/users/userInformation/userInformation'
				),
		),
	},
	{
		path: '/admin-console/manage-locations',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/locations/manageLocations/manageLocations'
				),
		),
		exact: true,
	},
	{
		path:
			'/admin-console/manage-locations/location-information/:locationId?',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/locations/locationInformation/locationInformation'
				),
		),
	},
	{
		path: '/admin-console/transfer-location-ownership',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/transferOwnership/transferLocationOwnership/transferLocationOwnership'
				),
		),
	},
	{
		path: '/admin-console/list-of-transfers',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/transferOwnership/listOftransfers/listOftransfers'
				),
		),
	},
	{
		path: '/admin-console/list-of-referrals',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/referAcolleague/listOfReferrals/listOfReferrals'
				),
		),
	},
	{
		path: '/admin-console/refer-a-colleague',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/referAcolleague/referAcolleague/referAColleague'
				),
		),
	},
	{
		path: '/admin-console/billing',
		component: lazy(
			() => import('./routes/adminConsole/billing/manage/billing'),
		),
		exact: true,
	},
	{
		path: '/admin-console/billing/payment-methods',
		component: lazy(
			() =>
				import(
					'./routes/adminConsole/billing/paymentMethods/paymentMethods'
				),
		),
	},
	{
		path: '/admin-console/billing/update-subscription',
		component: lazy(
			() => import('./routes/adminConsole/pricing/UpdateSubscription'),
		),
	},
	{
		path: '/how-it-works',
		component: lazy(
			() => import('./routes/footerRoutes/howItWorks/howItWorks'),
		),
	},
	{
		path: '/how-it-works-advisor',
		component: lazy(
			() =>
				import(
					'./routes/footerRoutes/howItWorksAdvisor/howItWorksAdvisor'
				),
		),
	},
	{
		path: '/medical-business-suite-application',
		component: lazy(
			() =>
				import(
					'./routes/footerRoutes/medicalBusinessSuite/medicalBusinessSuite'
				),
		),
	},
	{
		path: '/plans',
		component: lazy(() => import('./routes/footerRoutes/pricing/pricing')),
	},
	{
		path: '/add-company',
		component: lazy(() => import('./routes/addCompany/AddCompany')),
	},
	{
		path: '/add-credit-card',
		component: lazy(() => import('./routes/creditCard/AddCreditCard')),
	},
	{
		path: '/request-message',
		component: lazy(
			() => import('./routes/creditCard/RequestMessage/RequestMessage'),
		),
	},
	{
		path: '/verified-message',
		component: lazy(
			() => import('./routes/creditCard/VerifiedMessage/VerifiedMessage'),
		),
	},
];

function App(): ReactElement {
	const logedInUser = useAppSelector(selectLogedInUserInfo);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);
	const token = Cookies.get('token') || '';

	useEffect(() => {
		if (
			(logedInUser.token && logedInUser.token.length > 0) ||
			token.length > 0
		) {
			GetMyProfile(
				logedInUser.token && logedInUser.token.length > 0
					? logedInUser.token
					: token,
			).then(({ data, status }) => {
				if (status === 200) {
					if (data.user) {
						const userInfo = {
							avatar: data.user.photo ? data.user.photo : '',
							email: data.user.email,
							firstName: data.user.first_name,
							lastName: data.user.last_name,
							status: data.user.status,
							token: token,
							isAdvisor: data.user.isAdvisor,
							company: data.user.company,
							enableReview: data.user.enableReview,
							role: data.user.role,
							id: data.user._id,
							categoryPermissions: data.user.categoryPermissions,
						};
						dispatch(setProfile(userInfo));
					} else {
						localStorage.clear();
						setLoading(false);
						Cookies.remove('token');
					}
				} else {
					window.location.href =
						process.env.REACT_APP_PERLA_LINK || '';
				}
				setLoading(false);
			});
		} else {
			localStorage.clear();
			Cookies.remove('token');
			window.location.href = process.env.REACT_APP_PERLA_LINK || '';
			setLoading(false);
		}
	}, [token]);

	useEffect(() => {
		if (logedInUser?.company?.verified_by_perla === 'require_credit_card') {
			window.location.href = `${
				process.env.REACT_APP_PERLA_LINK
			}plans?service=${
				logedInUser.company?.type === 'home healthcare'
					? 'homehealthcare'
					: logedInUser.company?.type === 'long term care'
					? 'longtermcare'
					: logedInUser.company?.type
			}`
		}
	}, [JSON.stringify(logedInUser)]);

	const logoutProccess = cb => {
		setLoading(true);
		// e.preventDefault();
		LogoutService(logedInUser.token).then(({ data, status }) => {
			localStorage.clear();
			Cookies.remove('token');
			dispatch(resetLogedInUserState());
			dispatch(resetAllSearch());
			window.location.href = process.env.REACT_APP_PERLA_LINK || '';
			cb();
			setLoading(false);
		});
	};
	return (
		<div className="app">
			<Router>
				<Header
					logoutProccess={logoutProccess}
					logedInUser={logedInUser}
				/>
				{loading ? (
					<RouteLoader />
				) : (
					<Suspense fallback={<RouteLoader />}>
						<Switch>
							{routes.map((route, index) => (
								<Route
									key={index}
									exact={route.exact ?? false}
									path={route.path}
									component={route.component}
								/>
							))}
						</Switch>
					</Suspense>
				)}
				<Footer />
			</Router>
			<ToastContainer />
		</div>
	);
}

export default App;
