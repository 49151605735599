import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetPaymentMethodsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.PAYMET_METHODS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetPaymentAccountsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.PAYMET_ACCOUNTS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetSubscriptionsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async token => {
	return fetchService({
		url: Apis.SUBSCRIPTIONS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddPaymentMethodService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_PAYMENT_METHOD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const AddBackupPaymentMethodService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_BACKUP_PAYMENT_METHOD,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const UpdatePaymentMethodService: (
	paymentMethodId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	paymentMethodId,
	body,
	token,
) => {
	return fetchService({
		url: `${Apis.UPDATE_PAYMENT_METHOD}?paymentMethodId=${paymentMethodId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const UpgradeSubscriptionService: (
	subscriptionId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	subscriptionId,
	body,
	token,
) => {
	return fetchService({
		url: `${Apis.UPGRADE_SUBSCRIPTION}?subscriptionId=${subscriptionId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const DowngradeSubscriptionService: (
	subscriptionId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	subscriptionId,
	body,
	token,
) => {
	return fetchService({
		url: `${Apis.DOWNGRADE_SUBSCRIPTION}?subscriptionId=${subscriptionId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const DeleteSubscriptionService: (
	subscriptionId: number,
	token: string,
) => Promise<{ data: any; status: number }> = async (subscriptionId, token) => {
	return fetchService({
		url: `${Apis.DELETE_SUBSCRIPTION}?subscriptionId=${subscriptionId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

const CancelSubscription: (
	token: string,
) => Promise<{ data: any; status: number }> = async token => {
	return fetchService({
		url: Apis.CANCEL_SUBSCRIPTION,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: true,
		token: token,
	});
};

const UpdateSubscription: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.UPDATE_SUBSCRIPTION,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: true,
		token: token,
	});
};

export {
	GetPaymentMethodsService,
	GetPaymentAccountsService,
	GetSubscriptionsService,
	AddPaymentMethodService,
	AddBackupPaymentMethodService,
	UpgradeSubscriptionService,
	UpdatePaymentMethodService,
	DowngradeSubscriptionService,
	DeleteSubscriptionService,
	CancelSubscription,
	UpdateSubscription,
};
