import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faLink,
	faPhoneVolume,
} from '@fortawesome/free-solid-svg-icons';
import styles from './FooterContact.module.scss';

export default function FooterContact() {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<Button
				aria-describedby={id}
				className={styles.btn}
				variant="text"
				onClick={handleClick}
			>
				Contact
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<div className={styles.content}>
					<p id={styles.helpTime}>Mon-Fri, 9AM to 5PM EST</p>
					<p>
						<FontAwesomeIcon
							icon={faPhoneVolume}
							className={styles.icon}
						/>{' '}
						<a href="tel:202-800-5858">202-800-5858</a>
					</p>
					<p>
						<FontAwesomeIcon
							icon={faEnvelope}
							className={styles.icon}
						/>{' '}
						<a href="mailto:support@goperla.com">
							support@goperla.com
						</a>
					</p>
					<p>
						<FontAwesomeIcon
							icon={faLink}
							className={styles.icon}
						/>{' '}
						<a href="http://goperla.com/">www.goperla.com</a>
					</p>
				</div>
			</Popover>
		</div>
	);
}
