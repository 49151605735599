const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID || '';
const perlaLink = process.env.REACT_APP_PERLA_LINK;
const medicalLink = process.env.REACT_APP_MEDICAL_LINK;
const dentalLink = process.env.REACT_APP_DENTAL_LINK;
const ascLink = process.env.REACT_APP_ASC_LINK;
const homeHealthCareLink = process.env.REACT_APP_HOME_HEALTHCARE;
const longTermCareLink = process.env.REACT_APP_LONG_TERM_CARE;
const termsLink = process.env.REACT_APP_TERMS_LINK;
const advisorLink = process.env.REACT_APP_ADVISOR_LINK;
const academyLink = process.env.REACT_APP_ACADEMY_LINK;
const blogLink = process.env.REACT_APP_BLOG_LINK;
export {
	linkedinClientId,
	medicalLink,
	dentalLink,
	advisorLink,
	academyLink,
	blogLink,
	perlaLink,
	ascLink,
	termsLink,
	homeHealthCareLink,
	longTermCareLink
};
