import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllReferralsService: () => Promise<{
	data: any;
	status: number;
}> = async () => {
	return fetchService({
		url: Apis.REFERRALS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
	});
};

const ReferAColleagueService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.REFER_A_COLLEAGUE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

export { GetAllReferralsService, ReferAColleagueService };
