import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetCompanyService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string) => {
	return fetchService({
		url: Apis.GET_COMPANY,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetBillingDetailsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string) => {
	return fetchService({
		url: Apis.GET_BILLING_DETAILS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddCompanyService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_COMPANY,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const SelectPlanService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.SELECT_PLAN,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	AddCompanyService,
	SelectPlanService,
	GetCompanyService,
	GetBillingDetailsService,
};
