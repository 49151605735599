import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const AddCategoryFolderService: (
	token: string,
	body: {
		category_labels: string[];
		locationId: string;
		type: 'document' | 'equipment';
	},
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.ADD_CATEGORY_FOLDER,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const AddFolderService: (
	token: string,
	body: {
		folder_labels: string[];
		categoryId;
	},
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.ADD_FOLDER,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const GetAllCategoryFoldersService: (
	token: string,
	locationId: string,
	type: 'document' | 'equipment' | 'employee',
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, locationId: string, type: string) => {
	return fetchService({
		url: `${Apis.GET_CATEGORY_FOLDERS}?locationId=${locationId}&type=${type}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetCategoryFolderService: (
	token: string,
	categoryId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, categoryId: string) => {
	return fetchService({
		url: `${Apis.GET_CATEGORY_FOLDER}?categoryId=${categoryId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetFolderService: (
	token: string,
	folderId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, folderId: string) => {
	return fetchService({
		url: `${Apis.GET_FOLDER}?folderId=${folderId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const DeleteCategoryFolderService: (
	token: string,
	categoryId: string,
) => Promise<{ data: any; status: number }> = async (token, categoryId) => {
	return fetchService({
		url: `${Apis.DELETE_CATEGORY_FOLDER}/${categoryId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};
const DeleteFolderService: (
	token: string,
	folderId: string,
) => Promise<{ data: any; status: number }> = async (token, folderId) => {
	return fetchService({
		url: `${Apis.DELETE_FOLDER}/${folderId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

const SearchFoldersService: (
	token: string,
	locationId: string,
	type: 'document' | 'equipment',
	searchText: string,
) => Promise<{
	data: any;
	status: number;
}> = async (
	token: string,
	locationId: string,
	type: string,
	searchText: string,
) => {
	return fetchService({
		url: `${Apis.SEARCH_FOLDERS}?locationId=${locationId}&type=${type}&searchText=${searchText}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const EditCategoryOrderService: (
	token: string,
	body: {
		locationId: string;
		type: string;
		orderArray: string[];
	},
) => Promise<{ data: any; status: number }> = (token: string, body: any) => {
	return fetchService({
		url: `${Apis.CATEGORY_ORDER}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const MoveFolderService: (
	token: string,
	body: {
		folderId: string;
		destinationCategoryId: string;
	},
) => Promise<{ data: any; status: number }> = (token: string, body: any) => {
	return fetchService({
		url: `${Apis.MOVE_FOLDER}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const MoveFileService: (
	token: string,
	body: {
		fileId: string;
		destinationFolderId: string;
	},
) => Promise<{ data: any; status: number }> = (token: string, body: any) => {
	return fetchService({
		url: `${Apis.MOVE_FILE}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const GetNumberOfCategoryFilesService: (
	token: string,
	categoryId: string,
) => Promise<{ data: any; status: number }> = (
	token: string,
	categoryId: string,
) => {
	return fetchService({
		url: `${Apis.GET_NUMBER_OF_CATEGORY_FILES}?categoryId=${categoryId}`,
		method: 'GET',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};
const GetNumberOfFolderFilesService: (
	token: string,
	folderId: string,
) => Promise<{ data: any; status: number }> = (
	token: string,
	folderId: string,
) => {
	return fetchService({
		url: `${Apis.GET_NUMBER_OF_FOLDER_FILES}?folderId=${folderId}`,
		method: 'GET',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

const EditCategoryFolderService: (
	token: string,
	body: {
		categoryId: string;
		label: string;
	},
) => Promise<{ data: any; status: number }> = (token: string, body: any) => {
	return fetchService({
		url: `${Apis.EDIT_CATEGORY_FOLDER}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const EditFolderService: (
	token: string,
	body: {
		folderId: string;
		label: string;
	},
) => Promise<{ data: any; status: number }> = (token: string, body: any) => {
	return fetchService({
		url: `${Apis.EDIT_FOLDER}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	AddCategoryFolderService,
	AddFolderService,
	GetAllCategoryFoldersService,
	GetFolderService,
	DeleteCategoryFolderService,
	DeleteFolderService,
	SearchFoldersService,
	EditCategoryOrderService,
	MoveFolderService,
	MoveFileService,
	GetNumberOfCategoryFilesService,
	GetNumberOfFolderFilesService,
	EditCategoryFolderService,
	EditFolderService,
	GetCategoryFolderService,
};
