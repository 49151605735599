import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const GetAllLocationsService: (
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string) => {
	return fetchService({
		url: Apis.LOCATIONS,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetLocationService: (
	locationId: string,
	token: string,
) => Promise<{
	data: any;
	status: number;
}> = async (locationId: string, token: string) => {
	return fetchService({
		url: Apis.LOCATION + '/' + locationId,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

const AddLocationService: (
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (body, token) => {
	return fetchService({
		url: Apis.ADD_LOCATION,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const MakePrimaryLocationService: (
	locationId,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	locationId,
	token,
) => {
	return fetchService({
		url: Apis.MAKE_PRIMARY_LOCATION + '/' + locationId,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

const UpdateLocationService: (
	locationId: number,
	body: any,
	token: string,
) => Promise<{ data: any; status: number }> = async (
	locationId,
	body,
	token,
) => {
	return fetchService({
		url: `${Apis.UPDATE_LOCATION}/${locationId}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const DeleteLocationService: (
	locationId: string,
	token: string,
) => Promise<{ data: any; status: number }> = async (locationId, token) => {
	return fetchService({
		url: `${Apis.DELETE_LOCATION}/${locationId}`,
		method: 'DELETE',
		addContentTypeApplicationJson: true,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	GetAllLocationsService,
	GetLocationService,
	AddLocationService,
	UpdateLocationService,
	DeleteLocationService,
	MakePrimaryLocationService,
};
