import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import affiliateReducer from '../features/affiliates/affiliateSlice';
import searchReducer from '../features/search/searchSlice';
import servicesReducer from '../features/services/servicesSlice';
import userReducer from '../features/user/userSlice';
import logedInUserReducer from '../features/logedInUser/logedInUserSlice';
import categoryReducer from '../features/category/categorySlice';

const SESSION_KEY = 'PERLA_STORE';

const sessionStorageMiddleware = store => next => action => {
	let result = next(action);
	sessionStorage.setItem(SESSION_KEY, JSON.stringify(store.getState()));
	return result;
};

export const store = configureStore({
	reducer: {
		affiliate: affiliateReducer,
		search: searchReducer,
		services: servicesReducer,
		user: userReducer,
		logedInUser: logedInUserReducer,
		category: categoryReducer
	},
	preloadedState: JSON.parse(sessionStorage.getItem(SESSION_KEY) || '{}'),
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([sessionStorageMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
