import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Category } from '../../common/types';

export interface CategoryState {
	categories: Category[];
}

const initialState: CategoryState = {
	categories: [],
};

export const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setCatgories: (state, action: PayloadAction<CategoryState>) => {
			state.categories = action.payload.categories;
		},
		resetCategoryState: state => initialState,
	},
});

export const { setCatgories, resetCategoryState } = CategorySlice.actions;

export const selectCategories = (state: RootState) => state.category.categories;

export default CategorySlice.reducer;
