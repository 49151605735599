import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserState {
	firstName: string;
	lastName: string;
	email: string;
}

const initialState: UserState = {
	firstName: '',
	lastName: '',
	email: '',
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setPersonalInfo: (state, action: PayloadAction<UserState>) => {
			state.email = action.payload.email;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
		},
		resetUserState: state => initialState,
	},
});

export const {
	setPersonalInfo,
	resetUserState,
} = userSlice.actions;

export const selectPersonalInfo = (state: RootState) => state.user;

export default userSlice.reducer;
