import { ReactElement } from 'react';
import styles from './Footer.module.scss';
import { Button } from '@material-ui/core';
import FooterContact from './FooterContact';
import { medicalLink, termsLink } from '../constants';

export default function Footer(): ReactElement {
	return (
		<div className={styles.root}>
			<div className={styles.logoContainer}>
				<span>© 2022 Perla | All Rights Reserved.</span>
			</div>
			<div className={styles.btnsContainer}>
				<FooterContact />
				<Button
					component="a"
					href={termsLink}
					className={styles.btn}
					variant="text"
				>
					Terms of Service
				</Button>
				<Button
					component="a"
					href={termsLink}
					className={styles.btn}
					variant="text"
				>
					Privacy Policy
				</Button>
			</div>
		</div>
	);
}
