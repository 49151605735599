import { Apis } from '../constants/Apis';
import { fetchService } from './service';

const AddEmployeeService: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: Apis.ADD_EMPLOYEE,
		method: 'POST',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const EditEmployeeService: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: `${Apis.EDIT_EMPLOYEE}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};
const EditEmployeeByCategoryService: (
	token: string,
	body: any,
) => Promise<{ data: any; status: number }> = async (token, body) => {
	return fetchService({
		url: `${Apis.EDIT_EMPLOYEE_BY_CATEGORY}`,
		method: 'PUT',
		addContentTypeApplicationJson: true,
		body: body,
		showSuccessMessage: false,
		token: token,
	});
};

const GetAllEmployeesService: (
	token: string,
	locationId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, locationId: string) => {
	return fetchService({
		url: `${Apis.GET_ALL_EMPLOYEES}?locationId=${locationId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};
const GetEmployeeByCategoryService: (
	token: string,
	categoryId: string,
) => Promise<{
	data: any;
	status: number;
}> = async (token: string, categoryId: string) => {
	return fetchService({
		url: `${Apis.GET_EMPLOYEE_BY_CATEGORY}?categoryId=${categoryId}`,
		method: 'GET',
		addContentTypeApplicationJson: false,
		showSuccessMessage: false,
		token: token,
	});
};

export {
	AddEmployeeService,
	EditEmployeeService,
	EditEmployeeByCategoryService,
	GetAllEmployeesService,
	GetEmployeeByCategoryService
};
